exports.onInitialClientRender = () => {
  const css = document.createElement("link")
  css.href =
    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  css.rel = "stylesheet"
  document.getElementsByTagName("head")[0].appendChild(css)

  const js = document.createElement("script")
  js.src =
    "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
  js.async = true
  document.body.appendChild(js)
}
